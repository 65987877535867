export interface IServiceCard {
    title_en: string;
    title_nl: string;
    description_en: string;
    description_nl: string;
    pre_title_en: string;
    pre_title_nl: string;
    image: string;
}

const data: IServiceCard[] = [
    {
        pre_title_en: "Strenghten your team when you need it",
        pre_title_nl: "Strenghten your team when you need it",
        title_en: "IT Professionals On-Demand",
        title_nl: "IT Professionals On-Demand",
        description_en: "Add a new member to your team without skipping a beat! Our talented candidates are the very core of our business. We only work with professionals who have impressed us with their competence in their area of expertise, and who have demonstrated strong communication skills.",
        description_nl: "Add a new member to your team without skipping a beat! Our talented candidates are the very core of our business. We only work with professionals who have impressed us with their competence in their area of expertise, and who have demonstrated strong communication skills.",
        image: "/assets/images/services/it-professional-illustration.png",
    },
    {
        pre_title_en: "A budget friendly option",
        pre_title_nl: "A budget friendly option",
        title_en: "Remote workers",
        title_nl: "IT Professionals",
        description_en: "It’s a new era - the most qualified IT professionals are now within your budget. How? By working with talent abroad. Choosing remote workers can dramatically cut your costs without sacrificing quality. To make sure communication does not suffer, we choose candidates with a high proficiency in English and who are experienced in remote communication. ",
        description_nl: "It’s a new era - the most qualified IT professionals are now within your budget. How? By working with talent abroad. Choosing remote workers can dramatically cut your costs without sacrificing quality. To make sure communication does not suffer, we choose candidates with a high proficiency in English and who are experienced in remote communication. ",
        image: "/assets/images/services/remote-work-illustration.png",
    },
    {
        pre_title_en: "The fastest way to excellence",
        pre_title_nl: "The fastest way to excellence",
        title_en: "High Performance Teams",
        title_nl: "High Performance Teams",
        description_en: "“The whole is something besides the parts” said an ancient philosopher, and we’ve found this to be true. Synergy does not always happen, but when putting together a high performance team it’s our top priority. If you have a project that needs to be completed fast but don’t want to compromise quality, this is your solution.",
        description_nl: "“The whole is something besides the parts” said an ancient philosopher, and we’ve found this to be true. Synergy does not always happen, but when putting together a high performance team it’s our top priority. If you have a project that needs to be completed fast but don’t want to compromise quality, this is your solution.",
        image: "/assets/images/services/high-performance-team-illustration.png",
    },
    {
        pre_title_en: "Realize your ideas",
        pre_title_nl: "Realize your ideas",
        title_en: "Product Development",
        title_nl: "Product Development",
        description_en: "Do you have an exciting idea, but no tech department? Lucky for you, we have all the resources and manpower you need. Our experienced professionals will help you translate your vision into software. Before committing we’ll provide you with time and cost estimates, so you don’t have to worry about unexpected costs.",
        description_nl: "Do you have an exciting idea, but no tech department? Lucky for you, we have all the resources and manpower you need. Our experienced professionals will help you translate your vision into software. Before committing we’ll provide you with time and cost estimates, so you don’t have to worry about unexpected costs.",
        image: "/assets/images/services/product-development-illustration.png",
    }
];

export default data;
