import * as React from "react";
import { Helmet } from "react-helmet";
import Clients from "../components/clients/Clients";
import ContactForm from "../components/contact-form/ContactForm";
import Hero from "../components/hero/Hero";
import Services from "../components/services/Services";
import TextBlock from "../components/text-block/TextBlock";

// markup
const IndexPage = () => {
    return (
        <>
            <Helmet>
                <title>INEPRA</title>
            </Helmet>

            <Hero />
            <TextBlock />
            <Clients />

            <Services />
            <ContactForm />
        </>
    );
};

export default IndexPage;
