import React from "react";

const TextBlock = () => {
    return (
        <>
            <section id="down" className="dark-bg-1 flex-min-height-box">
                <div className="flex-min-height-inner">
                    <div className="container small top-bottom-padding-120">
                        <div data-animation-container className="flex-container">
                            <div className="twelve-columns text-center">
                                <h2 className="large-title text-height-12">
                                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Zeal Is Part Of The Deal">Zeal Is Part Of The Deal</span><br />
                                </h2>
                            </div>
                            <div className="six-columns">
                                <div className="content-right-margin-20">
                                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">We take talent seriously. It is our core belief that by putting our candidates’ needs and wishes first, we can provide the best possible service. We give them the ability to build the diverse portfolio of a freelancer while maintaining financial stability, and as a result, our workers are highly experienced working in diverse environments, and adapt quickly to new challenges.</p>
                                </div>
                            </div>
                            <div className="six-columns">
                                <div className="content-left-margin-20">
                                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay03" data-animation="fade-anim">
                                    So, if you want to grow your team, assemble a new one, or build a project, we can help. On a tight budget? Take a look at our remote workers option. On a tight schedule? That’s what our high performance teams are for. If you’re in the market for IT services, <a href="tel:+31320859935"  className="pointer-large animsition-link p-style-large red-color" rel="noreferrer">give us a call</a> or <a className="pointer-large animsition-link p-style-large red-color" href="#contact">message us</a>, and we’ll see exactly what we can do for you - no strings attached.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default TextBlock;
