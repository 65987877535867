import React, { useEffect } from "react";
import TypeWriter, { Options as TWOptions } from "typewriter-effect";
import Button from "../button/Button";

import "../../../static/assets/js/particles.js";
// @ts-ignore
import { initParticles } from "../../../static/assets/js/particles_init.js";

const typeWriterOptions: TWOptions = {
    strings: ["Java Developer", "High Performance Team", "Software tester", "SCRUM Master"],
    autoStart: true,
    loop: true,
    delay: 50,
    deleteSpeed: 25,
};

const Hero = () => {
    useEffect(() => {
        initParticles();
    }, []);

    return (
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
            <div className="page-head-bg" id="particles-js" style={{ backgroundImage: "url(assets/images/home/anonymous-person.png)", }}></div>

            <div className="flex-min-height-inner">
                <div className="container top-bottom-padding-120">
                    <h2 className="overlay-loading2 medium-title text-color-4">The search is over</h2>
                    <h3 className="large-title-bold text-color-4">
                        <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">We have your next</span><br />
                        <span className="overlay-loading2 overlay-light-bg-1 tr-delay02 red-color" id="hero-typewriter">
                            <TypeWriter options={typeWriterOptions} />
                        </span>
                    </h3>
                    <Button classes="top-margin-30" href="/about">Read more</Button>
                </div>
            </div>
              
            <a href="#down" className="scroll-btn pointer-large">
                <div className="scroll-arrow-box">
                    <span className="scroll-arrow"></span>
                </div>
                <div className="scroll-btn-flip-box">
                    <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                </div>
            </a>
        </section>
    );
};

export default Hero;
