import React from "react";
import { Link } from "gatsby";

interface Props {
    children: string;
    classes?: string;
    type?: "arrow" | "border";
    backgroundColor?: "dark" | "transparent";
    color?: "primary" | "default";
    href: string;
}

const Button = (props: Props) => {
    const text = props.children;

    const {
        type = "border",
        // backgroundColor = "transparent",
        color = "default",
        classes = "",
    } = props;

    const buttonType = type + "-btn";
    const buttonClass = color === "primary" ? "border-btn-red" : "";

    return (
        <Link className={`${buttonType}-box ${buttonClass} pointer-large ${classes}`} to={props.href}>
            <div className={`${buttonType}-inner`}>
                <a className={buttonType} data-text={text}>{text}</a>
            </div>
        </Link>
    );
};

export default Button;
