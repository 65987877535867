import React from "react";
import data from "./services-data";

import "./services.scss";

const Services = () => {
    return (

        <section className="light-bg-1" data-midnight="black">
            <div className="container bottom-padding-120 top-padding-120">
                <div data-animation-container className="text-center bottom-margin-60">
                    <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">OUR SERVICES</h2><br />
                    <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim"></p>
                </div>
            
                {data.map((card, i) => (
                    <div key={i} className={`service ${i % 2 !== 0 ? "is-reversed" : ""}`}>
                        <div data-animation-container className="service__text">
                            <span className="small-title-oswald red-color overlay-anim-box2 bottom-margin-5" data-animation="overlay-anim2">{card.pre_title_en}</span>
                            <h3 className="title-style text-color-1">
                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">{card.title_en}</span><br />
                            </h3>
                            <p data-animation-child className="p-style-small text-color-2 fade-anim-box tr-delay02 top-margin-5" data-animation="fade-anim">{card.description_en}</p>
                        </div>
                        <img src={card.image} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;
